import * as React from 'react'
import { Link, graphql } from 'gatsby'
import styled from '@emotion/styled'
import Img, { FixedObject, FluidObject } from 'gatsby-image'

import Page from '../components/Page'
import Container from '../components/Container'
import IndexLayout from '../layouts'

const AppLink = styled(Link)`
  font-size: 1.5rem;
  font-weight: 600;
`

const AppContainer = styled.div`
  display: flex;
  flex-direction: row;
`

const ImageContainer = styled.div`
  padding: 1rem;
  width: 100px;
  height: 100px;
`

const DescriptionContainer = styled.div`
  padding: 1rem;
  display: flex;
  align-items: center;
`

interface AppsPageProps {
  data: {
    houstimateImage: {
      childImageSharp: {
        fixed: FixedObject
        fluid: FluidObject
      }
    }
  }
}

const AppsPage: React.FC<AppsPageProps> = props => {
  // eslint-disable-next-line react/destructuring-assignment
  const { houstimateImage } = props.data

  return (
    <IndexLayout>
      <Page>
        <Container>
          <h1>Apps</h1>
          <AppLink to="/houstimate/">
            <span>
              <AppContainer>
                <ImageContainer>
                  <Img fluid={houstimateImage.childImageSharp.fluid} />
                </ImageContainer>
                <DescriptionContainer>Houstimate</DescriptionContainer>
              </AppContainer>
            </span>
          </AppLink>
        </Container>
      </Page>
    </IndexLayout>
  )
}

export default AppsPage

export const query = graphql`
  query {
    houstimateImage: file(relativePath: { eq: "houstimate-icon.png" }) {
      childImageSharp {
        fluid(maxWidth: 125, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
